import { grommet, ThemeType } from "grommet";
import { deepMerge } from "grommet/utils";

export const groceryscout: ThemeType = deepMerge(grommet, {
  global: {
    colors: {
      secondary: {
        dark: "#3AB54A",
        light: "#3AB54A",
      },
      brand: {
        dark: "#FF727A",
        light: "#FF727A",
      },
      background: {
        dark: "#111111",
        light: "#F7F7F9",
      },
      "background-back": {
        dark: "#080806",
        light: "#F7F7F9",
      },
      "background-front": {
        dark: "#222222",
        light: "#FFFFFF",
      },
      "background-contrast": {
        dark: "#F7F7F911",
        light: "#08080611",
      },
      text: {
        dark: "#FFFFFF",
        light: "#3A3A3A",
      },
      "text-strong": {
        dark: "#FFFFFF",
        light: "#000000",
      },
      "text-weak": {
        dark: "#CACACA",
        light: "#4A4A4A",
      },
      "text-xweak": {
        dark: "#9A9A9A",
        light: "#6A6A6A",
      },
      border: {
        dark: "#444444",
        light: "#CCCCCC",
      },
    },
    font: {
      family: "Montserrat, sans-serif",
      size: "18px",
      height: "24px",
      maxWidth: "432px",
    },
    focus: {
      border: {
        color: "rgba(0,0,0,0.1)",
      },
    },
  },
  button: {
    extend: `
      color: #FFFFFF;
      font-weight: bold;
    `,
  },
  heading: {
    font: {
      family: "Nunito Sans, sans-serif",
    },
  },
  accordion: {
    border: {
      color: "brand"
    }
  }
});
