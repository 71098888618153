import { Grommet } from "grommet";
import NextApp from "next/app";
import React from "react";
import { createGlobalStyle } from "styled-components";
import sanitizeCss from "sanitize.css";
import { groceryscout } from "../themes";
import { HomeLayout } from "../components";
import imageGalleryCss from "react-image-gallery/styles/css/image-gallery.css";

const GlobalStyles = createGlobalStyle`
  ${sanitizeCss}

  ${imageGalleryCss}


  .image-gallery-thumbnail {
    border: 2px solid transparent;
    transition: border 0s ease-out;

    @media (max-width: $ig-small-screen) {
      border: 1px solid transparent;
    }
  }

  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:hover,
  .image-gallery-thumbnail:focus {
    border: 2px solid #FF727A;

    @media (max-width: $ig-small-screen) {
      border: 1px solid #FF727A;
    }
  }

  body {
    background: #F7F7F9;
  }
`;

export default class App extends NextApp {
  render() {
    const { Component, pageProps } = this.props;

    const getLayout =
      Component.getLayout ||
      ((page) => <HomeLayout>{page}</HomeLayout>);

    return (
      <Grommet theme={groceryscout}>
        {getLayout(<Component {...pageProps} />)}
        <GlobalStyles />
      </Grommet>
    );
  }
}
