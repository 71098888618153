import React from "react";
import Head from "next/head";
import {
  ResponsiveContext,
  Main,
  Header,
  Footer,
  Box,
  Heading,
  Paragraph,
  Text,
  Image,
  Anchor,
} from "grommet";
import { Down, Facebook, Twitter, Instagram } from "grommet-icons";
import {
  FaCreativeCommons,
  FaCreativeCommonsBy,
  FaCreativeCommonsSa,
} from "react-icons/fa";

const HomeLayout = ({ children }) => {
  const year = (new Date()).getFullYear();
  return (
    <>
      <Head>
        <title>Original Elephant Morby</title>
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap"
          rel="stylesheet"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#39b54a" />
        <meta name="apple-mobile-web-app-title" content="Grocery Scout" />
        <meta name="application-name" content="Grocery Scout" />
        <meta name="msapplication-TileColor" content="#00a300" />
        <meta name="theme-color" content="#39b54a" />
        <meta
          name="title"
          content="Original Elephant Morby – Pre-order now"
        />
        <meta
          name="description"
          content=""
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://groceryscout.co.uk" />
        <meta
          property="og:title"
          content="Original Elephant Morby – Pre-order now"
        />
        <meta
          property="og:description"
          content=""
        />
        <meta property="og:image" content="/site-screenshot.png" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://groceryscout.co.uk/" />
        <meta
          property="twitter:title"
          content="Original Elephant Morby – Pre-order now"
        />
        <meta
          property="twitter:description"
          content=""
        />
        <meta property="twitter:image" content="/site-screenshot.png" />
      </Head>

      <ResponsiveContext.Consumer>
        {(screenSize) => (
          <>
            <Header>
              <Box
                margin={{
                  bottom: "small",
                  horizontal: "auto",
                  top: "large",
                }}
                width="large"
                pad={{
                  horizontal: "small"
                }}
              >
                <Anchor href="/">
                  <Image
                    height={screenSize == "small" ? "55px" : "70px"}
                    src="morby_logo.png"
                  />
                </Anchor>
              </Box>
            </Header>

            <Main
              margin={{
                horizontal: "auto",
                vertical: "small",
              }}
              width="large"
            >
              <Box
                pad={{ horizontal: screenSize == "small" ? "large" : "small" }}
              >
                {children}
              </Box>
            </Main>
            <Footer>
              <Box>
                <Image
                  height={screenSize == "small" ? "100px" : "200px"}
                  src="foliage_left.png"
                />
              </Box>
              <Box>
                <Text>
                  &copy; Mooi Design {year} – Design by&nbsp;
                  <Anchor href="https://www.instagram.com/oneoseven107/" target="_blank">ONE-O-SEVEN</Anchor>
                </Text>
              </Box>
              <Box>
                <Image
                  height={screenSize == "small" ? "100px" : "200px"}
                  src="foliage_right.png"
                />
              </Box>
            </Footer>
          </>
        )}
      </ResponsiveContext.Consumer>
    </>
  );
};

export const getHomeLayout = (page) => (
  <HomeLayout>{page}</HomeLayout>
);

export default HomeLayout;
